"use client";

import Image from "next/image";
import { useState } from "react";

export function K33NewsLetterForm() {
  //TODO: form logic here
  const [submitted, setSubmitted] = useState(false);

  const [email, setEmail] = useState("");

  return (
    <div
      id="newsletter-form-container"
      className="flex h-[24.5625rem] w-full items-center justify-center bg-[url('/images/backgrounds/62be61771890ddf09c546efb76c43431.jpg')] bg-cover bg-center"
    >
      <div
        id="newsletter-form-padding"
        className="flex h-fit w-full max-w-5xl flex-col items-center justify-center gap-5 px-4 sm:px-7 lg:max-w-6xl xl:px-0"
      >
        <div
          id="newsletter-form-grey-box"
          className="flex w-full flex-col items-center justify-center gap-10 rounded-[4px] bg-[#edeff7] px-7 py-10 lg:flex-row lg:justify-between lg:gap-10 lg:px-8"
        >
          <div
            id="newsletter-info-container"
            className="flex w-full max-w-md flex-row gap-8 font-manrope"
          >
            <Image
              className="fill-[#2f2d3b] opacity-30"
              width={55}
              height={64}
              src="/images/icons/post.svg"
              alt="Post icon"
            />
            <div className="flex flex-col gap-2">
              <h3 className="text-base font-semibold md:text-2xl">
                Subscribe to our newsletter
              </h3>
              <p className="text-sm">
                Get monthly email updates about the improvements and roadmaps
                that might change.
              </p>
            </div>
          </div>
          {submitted ? (
            <div className="flex max-h-[4.5rem] w-full max-w-lg flex-row items-center justify-center rounded-[2px] border border-[#4eca4e] bg-[#b8ebb8] py-5 text-center">
              Thank you for subscribing!
            </div>
          ) : (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setSubmitted(true);
              }}
              className="flex max-h-[4.5rem] w-full max-w-lg flex-row items-center justify-between rounded-[4px] bg-white py-2 pr-2"
            >
              <input
                required={true}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="Enter your email"
                className="h-[4.5rem] min-w-[5rem] pl-7 font-manrope outline-none"
              />
              <button
                type="submit"
                className="flex h-[3.375rem] items-center rounded-[4px] bg-[#a54242] py-3 px-10 text-center font-manrope font-semibold text-white transition-colors duration-300 ease-in-out hover:bg-[#803333] focus:bg-[#803333]"
              >
                Subscribe
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
