"use client";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { PropsWithChildren } from "react";
import { K33NewsLetterForm } from "./K33NewsLetterForm";

export function K33Footer() {
  const path = usePathname();
  const showFooter =
    path === "/" || path?.includes("auth") || path?.includes("bank");

  return showFooter ? (
    <div
      id="footer-container"
      className="flex h-fit w-full flex-col items-center justify-center border border-white bg-[#fbfbfb] font-manrope"
    >
      <K33NewsLetterForm />
      <div
        id="footer-padding"
        className="flex w-full max-w-5xl flex-col gap-5 px-4 pt-10 sm:px-7 lg:max-w-6xl xl:px-0"
      >
        <div
          id="footer-menu-container"
          className="flex flex-col gap-16 py-10 md:flex-row md:py-16"
        >
          <div id="footer-info-container" className="flex flex-col gap-4">
            <a href="https://k33.com" className="text-4xl text-[#a54242]">
              <Image
                width={64}
                height={64}
                src="/images/logo/K33-Letters-Red.svg"
                alt="K33 Logo"
              />
            </a>
            <p className="text-sm text-[#2f2d3b]">
              Trade cryptocurrency with confidence.
            </p>
            <a
              id="contact"
              className="flex flex-row items-center gap-2 text-sm font-medium text-[#2f2d3b] transition-colors duration-200 ease-in-out hover:text-[#a54242]"
              target="_blank"
              href="mailto:contact@k33.com?subject=Website%20Inquiry"
              rel="noreferrer noopener"
            >
              <Image
                className="opacity-50"
                width={20}
                height={16}
                src="/images/icons/post.svg"
                alt="Post icon"
              />
              contact@k33.com
            </a>
          </div>

          <div className="hidden w-full flex-row gap-16 md:flex lg:gap-32">
            <K33FooterCol title="Products">
              <K33FooterColButton
                name="Trading"
                href="https://k33.com/markets"
              />
              <K33FooterColButton
                name="Custody"
                href="https://k33.com/custody"
              />
              <K33FooterColButton name="Fund" href="https://k33.com/fund" />
              <K33FooterColButton name="Lending" href="/" labelText="soon" />
            </K33FooterCol>

            <K33FooterCol title="Company">
              <K33FooterColButton
                name="About us"
                href="https://k33.com/company"
              />
              <K33FooterColButton
                name="Updates"
                href="https://k33-web.webflow.io/company-news"
              />
            </K33FooterCol>

            <K33FooterCol title="Socials">
              <K33FooterColButton
                name="X (Twitter)"
                href="https://x.com/K33HQ"
              />
              <K33FooterColButton
                name="Linkedln"
                href="https://linkedin.com/company/k33"
              />
            </K33FooterCol>

            <K33FooterCol title="Legal">
              <K33FooterColButton
                name="Privacy Policy"
                href="https://k33.com/privacy"
              />
              <K33FooterColButton
                name="Terms and Conditions"
                href="https://k33.com/terms-and-conditions"
              />
            </K33FooterCol>
          </div>
        </div>
        <br />
        <br />
      </div>
      <div className="flex w-full flex-col items-center gap-4">
        <div
          id="footer-separator-line"
          className="h-[0.05rem] w-full bg-text opacity-20"
        />
        <div
          id="footer-copyright"
          className="flex h-12 w-full max-w-5xl flex-row items-center justify-between gap-5 py-14 px-4 font-manrope text-sm font-normal text-text sm:px-7 lg:max-w-6xl xl:px-0 "
        >
          <div className="h-10 w-20">
            <Image
              alt="K33 logo"
              width={232}
              height={70}
              src="/images/logo/K33_Red.svg"
            />
          </div>

          <p>Copyright © 2024 K33. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
}

function K33FooterCol(props: { title: String } & PropsWithChildren) {
  const { title } = props;

  return (
    <div className="flex flex-col items-start gap-4 text-[#2f2d3b]">
      <h3 className="text-base font-semibold"> {title}</h3>
      {props.children}
    </div>
  );
}

function K33FooterColButton(
  props: { name: string; labelText?: string } & JSX.IntrinsicElements["a"]
) {
  const { name, labelText, ...aProps } = props;

  return (
    <a
      {...aProps}
      rel="noopener noreferrer"
      target="_blank"
      className="relative cursor-pointer text-sm text-[#2f2d3b] transition-colors duration-200 ease-in-out hover:text-[#a54242]"
    >
      {name}
      {labelText && (
        <label className="absolute -top-1 left-[3.4rem] text-sm font-semibold text-[#a54242]">
          {labelText}
        </label>
      )}
    </a>
  );
}
